/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  /*************************************************************
                        CUSTOM CODE
      Astra Child theme for Art Of Living Retreat Center 
  --------------------------------------------------------------
  NOTES FOR DEVELOPERS:
  - Use tabs of two spaces for best reading experience
  --------------------------------------------------------------
    INDEX
    1.    General Scripts	
    1.1.  Astra Desktop Menu
    1.1.1	Make the desktop child menu open on click instead of
          hover
    1.1.2	Toggle the Desktop header events search bar 
    1.2.  Astra Mobile Menu
    1.1.3	Mobile Offcanvas
    1.2   General slider element
    1.3   Events recently viewed slider
    1.5   Single Event Mobile Toggle
    1.6   Gray out and disable links in passed events
    1.7   Pillar page template
 --------------------------------------------------------------
    CODE STARTS HERE
  -------------------------------------------------------------*/

  var Astra = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages               
        
        /*------------------------------------------------------------
          1.1.1	Make the desktop child menu open on click instead of
                hover
        -------------------------------------------------------------*/
        if ( matchMedia('screen and (min-width: 1024px)').matches ) {

          // New menu with outside click detection
          /*const $menu = $(".main-header-menu > .menu-item-has-children > a");
          const $menuToggler =*/

         /*$(document).mouseup(function (e) {
            if (!$(".main-header-menu > .menu-item-has-children").is(e.target) // if the target of the click isn't the container...
            && $(".main-header-menu > .menu-item-has-children").has(e.target).length === 0) // ... nor a descendant of the container
            {
              $(".main-header-menu > .menu-item-has-children").removeClass('is-active');
           }
          });
          */
        
          const $subMenus = $(".main-header-menu > .menu-item-has-children");
          const $menuToggle = $(".main-header-menu > .menu-item-has-children > a");

          $menuToggle.click(function(e) {
            e.preventDefault();

            if ($(this).parent().hasClass('active')) {
              $subMenus.removeClass('active');
              $(this).parent().removeClass('active');
            } else {
              $subMenus.removeClass('active');
              $(this).parent().addClass('active');
            }
            
          });

          // Toggle of the menu if it is clicked outside

          $(document).mouseup(function (e) {
            if (!$menuToggle.is(e.target) && $subMenus.has(e.target).length === 0) //  if the target of the click isn't the container... nor a descendant of the container
            {
              $subMenus.removeClass('active');
           }
          });

          /* Sticky Header */
          $(window).scroll(function(){
            if ($(window).scrollTop() > 50) {
                $('.site-simple-header').addClass('fixed-header');
            }
            else {
                $('.site-simple-header').removeClass('fixed-header');
            }
          });

          $(window).scroll(function(){
            if ($(window).scrollTop() > 480) {
                $('#description, #stickyEventSidebar4').addClass('fixed');
            }
            else {
                $('#description, #stickyEventSidebar4').removeClass('fixed');
            }
          });

          
        
          /*$('.searchform').on('keyup keypress', function(e) {
            var keyCode = e.keyCode || e.which;
            if (keyCode === 13) {
                e.preventDefault();
                return false;
            }
            $(this).find('.submit-wrapper').hide();
          });*/

        }

        /*------------------------------------------------------------
          1.1.2	Toggle the Desktop header events search bar 
        -------------------------------------------------------------*/
        $('.main-header-bar-wrap .join-a-retreat, .site-simple-header .search-cale, .site-simple-header .join-a-retreat').click(function(e) {
          e.preventDefault();
          $('.events-search-bar').toggleClass('_visible');
          $('.search-overlay').fadeIn();
        });

        // Make the mobile close button work
        $('.header-events-search-bar #close_events_bar').click(function(e) {
          e.preventDefault();
          $('.events-search-bar').removeClass('_visible');
          $('.search-overlay').fadeOut();
        });

        $('#close_events_bar_desktop').click(function(e) {
          e.preventDefault();
          $('.events-search-bar').toggleClass('_visible');
          $('.search-overlay').fadeOut();
        });

        // Custom Algolia Search
        /*function algolia_load_assets() {                
          $algoliaPath = '/js/algolia-search.js';
          $algoliaVersion = date("ymd-Gis", filemtime(get_template_directory() . $algoliaPath));
          wp_enqueue_script('algolia-search', get_template_directory_uri() . $algoliaPath, array('algolia-instant-search'), $algoliaVersion, true);
        }*/
      

        /*------------------------------------------------------------
          1.1.3	Mobile Offcanvas
        -------------------------------------------------------------*/
        $('#new-main-nav').hcOffcanvasNav({
          maxWidth: 1279,
          pushContent: '#offcanvaswrapper',
          position: 'right',
          levelSpacing: 0,
          levelTitles: true,
          navTitle: ' '
          //customToggle: '.ast-mobile-menu-buttons .ast-button-wrap .mobile-toggle-offcanvas-wrapper'    
        });
      
        
        // Floater
        $(document).ready(function() {
          $('#sticky-menu a').bind('click', function(eve) {
              eve.preventDefault(); // prevent hard jump, the default behavior
      
              var target = $(this).attr("href"); // Set the target as variable

              // perform animated scrolling by getting top-position of target-element and set it as scroll target
              $('html, body').stop().animate({
                  scrollTop: $(target).offset().top
              }, 600, function() {
                  //location.hash = target; //attach the hash (#jumptarget) to the pageurl
              });
      
              return false;
          });
          /* Header Infobar (COVID) */
        });
                
             
        /*------------------------------------------------------------
          1.5   Single  Envent Mobile Toggle
        -------------------------------------------------------------*/
        $( '#toggleSearchEvents' ).click(function() {
          console.log('clicked');
          $( '#SearchEventsFormWrapper' ).toggleClass( "visible" );
        });

        /*------------------------------------------------------------
          1.6   Generic same level read more expansion script
        -------------------------------------------------------------*/
        $( '.toggleReadMoreSection' ).click(function(e) {
          e.preventDefault();
          console.log('clicked');

          $( '.toggleReadMoreSectionTarget' ).toggleClass( "expanded" );
          
          $( '.toggleReadMoreSection' ).toggleClass( "expanded" );

          if ($( '.toggleReadMoreSection' ).hasClass("active")) {
            $( '.toggleReadMoreSection' ).removeClass("active");
          } else {
            $( '.toggleReadMoreSection' ).addClass("active");
          }
        });

        /*------------------------------------------------------------
          1.7   Generic limit character and read more only for mobile
        -------------------------------------------------------------*/
        if ($(window).width () < 768) {
          
                      
            var ellipsestext = "...";
            //var moretext = "Show More";
              
              $('.show-read-more .elementor-image-box-description, .show-read-more .elementor-text-editor .teaser').each(function(index, value) {
                    //alert(index);
                    var showChar = $(this).parents('div').closest(".show-read-more").attr('data-maxlength');
                    //alert (showChar);       
                    var content = $(this).html();
                    if(content.length > showChar) {
            
                        var c = content.substr(0, showChar);
                        var h = content.substr(showChar, content.length - showChar);                        
                     
                          //var html = c + '<span class="moreellipses">' + ellipsestext + '</span><span class="morecontent"><span>' + h + '</span>&nbsp;&nbsp;<a href="" class="morelink">' + moretext + '</a></span>';
                          var html = c + '<span class="moreellipses">' + ellipsestext + '</span><span class="morecontent"><span>' + h + '</span>';
                        
                        $(this).html(html);
                    }                     
                    
              });
              
              $(".show-read-more .complete").after("<span class='showmore'>Show More</span>");
              $('.showmore').parent("div").find(".teaser .morecontent .morelink").hide();
                          
              $(".showmore").on("click", function() {
                if($(this).attr('data-click-state') == 1) {  
                  $(this).attr('data-click-state', 0);

                  $(this).parent("div").find(".teaser .moreellipses").show();
                  $(this).parent("div").find(".teaser .morecontent span").hide();
                  $(this).text("Show More").siblings(".complete").hide();

                } else {
                  $(this).attr('data-click-state', 1);

                  $(this).parent("div").find(".teaser .moreellipses").hide();
                  $(this).parent("div").find(".teaser .morecontent span").show();
                  $(this).siblings(".teaser .morecontent span").show();
                  $(this).text("Show Less").siblings(".complete").show();
                }
              });
           
        }  

       /*------------------------------------------------------------
          1.8   Copy Discount Code to Clipboard
        -------------------------------------------------------------*/

        $(document).on('click', '.discount-code button', function(ev) {             
            copyToClipboardMsg(document.getElementById("couponCopyTarget"), "discountmsg");  
            //$(this).addClass('Copied!'); 
            var $copyBtnID = $('#couponCopyBtn');
            if($copyBtnID.length){
              $(this).text('Copied!');
              setTimeout(function(eve) {
                $('.discount-code button').text('Copy');
              } , 3000);
            }
                   
        });

        function copyToClipboardMsg(elem, msgElem) {
            var succeed = copyToClipboard(elem);
            var copyMsg;
            if (!succeed) {
              copyMsg = "Copy not supported or blocked. Press Ctrl+c to copy.";
            } else {
              copyMsg = "Copied!";                
            }
            if (typeof msgElem === "string") {
                msgElem = document.getElementById(msgElem);
            }
            msgElem.innerHTML = copyMsg;            
            setTimeout(function() {
                msgElem.innerHTML = "";
            }, 2000);
        }
        
        function copyToClipboard(elem) {
            // create hidden text element, if it doesn't already exist
            var copyTarget;
            var targetId = "_hiddenCopyText_";
            var isInput = elem.tagName === "INPUT" || elem.tagName === "TEXTAREA";
            var origSelectionStart, origSelectionEnd;
            if (isInput) {
                // can just use the original source element for the selection and copy
                copyTarget = elem;
                origSelectionStart = elem.selectionStart;
                origSelectionEnd = elem.selectionEnd;
            } else {
                // must use a temporary form element for the selection and copy
                copyTarget = document.getElementById(targetId);
                if (!copyTarget) {
                    copyTarget = document.createElement("textarea");
                    copyTarget.style.position = "absolute";
                    copyTarget.style.left = "-9999px";
                    copyTarget.style.top = "0";
                    copyTarget.id = targetId;
                    document.body.appendChild(copyTarget);
                }
                copyTarget.textContent = elem.textContent;
            }
            // select the content
            var currentFocus = document.activeElement;
            copyTarget.focus();
            copyTarget.setSelectionRange(0, copyTarget.value.length);
            
            // copy the selection
            var succeed;
            try {
                succeed = document.execCommand("copy");
            } catch(e) {
                succeed = false;
            }
            // restore original focus
            if (currentFocus && typeof currentFocus.focus === "function") {
                currentFocus.focus();
            }
            
            if (isInput) {
                // restore prior selection
                elem.setSelectionRange(origSelectionStart, origSelectionEnd);
            } else {
                // clear temporary content
                copyTarget.textContent = "";
            }
            return succeed;
        }
        
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Single Events
    'single_wcp_event': {
      init: function() { // Add extra code here for event // Kavi

        // Adjust the sticky nav and sidebar for tablet and mobile
        /*if ( matchMedia('screen and (max-width: 1023px)').matches ) {
          stickyEventNav = $('#stickyEventNav');
          stickyEventSidebar = $('#stickyEventSidebar');

          // Apply top nav offset if the nav is being used
          if (stickyEventNav.length) {
            stickyEventNav.data('settings').sticky_offset = {"unit":"px","size":45};
          }

          // Apply the sidebar offset if the sidebar is being used
          if (stickyEventSidebar.length) {
            stickyEventSidebar.data('settings').sticky_offset = {"unit":"px","size":110};
          }

        }*/

       /*
        * Replace all SVG images with inline SVG
        */
        /*jQuery('.event_taxonomy_shortcode .event_taxonomy_term .image-wrapper img').each(function(){
          var $img = jQuery(this);
          var imgID = $img.attr('id');
          var imgClass = $img.attr('class');
          var imgURL = $img.attr('src');

          jQuery.get(imgURL, function(data) {
              // Get the SVG tag, ignore the rest
              var $svg = jQuery(data).find('svg');

              // Add replaced image's ID to the new SVG
              if(typeof imgID !== 'undefined') {
                  $svg = $svg.attr('id', imgID);
              }
              // Add replaced image's classes to the new SVG
              if(typeof imgClass !== 'undefined') {
                  $svg = $svg.attr('class', imgClass+' replaced-svg');
              }

              // Remove any invalid XML tags as per http://validator.w3.org
              $svg = $svg.removeAttr('xmlns:a');

              // Check if the viewport is set, if the viewport is not set the SVG wont't scale.
              if(!$svg.attr('viewBox') && $svg.attr('height') && $svg.attr('width')) {
                  $svg.attr('viewBox', '0 0 ' + $svg.attr('height') + ' ' + $svg.attr('width'));
              }

              // Replace image with new SVG
              $img.replaceWith($svg);

          }, 'xml');

        });*/

        /*------------------------------------------------------------
          1.6   Gray out and disable links in passed events
        -------------------------------------------------------------*/
        var singleEventEndDate = $( "#singleEventEndDate .elementor-text-editor" );
        var singleEventEndDateText = singleEventEndDate.text();

        if ( singleEventEndDateText.length ) {
          var eventDateNormalized = Date.parse(singleEventEndDateText);
          var todayDate = new Date();

          //console.log(todayDate);

          if (todayDate > eventDateNormalized) {
            //console.log('old event');

            $('#singleEventDesktopRegister').addClass('grayed-out-link');
            $('#singleEventDesktopRegister').click(function(event) {
              event.preventDefault();
            });
          
            $('#singleEventMobileRegister').addClass('grayed-out-link');
            $('#singleEventMobileRegister').click(function(event) {
              event.preventDefault();
            });
            
          }
          
        }
        
        // Turn sections into accordions
        $(".elementor-widget-theme-post-content").accordion({
          header : ".event-accordion-heading",
          heightStyle : "content", /*Allows full collapse*/
          collapsible: true, /*collapses sections*/
          active:false,
          navigation:true
        });

        // Make only the first one expanded on mobile
        if ( matchMedia('screen and (max-width: 1023px)').matches ) {
          $(".elementor-widget-theme-post-content").accordion({active: 0});
        }

        // Make everything open by default on desktop
        if ( matchMedia('screen and (min-width: 1024px)').matches ) {
          $('.elementor-widget-theme-post-content .ui-accordion-content').show();
        }

        // Schedule a consultant form (Sticky)
        $("#sclose a").addClass('collapse');
        
        $("#sclose a").click( function(event){
          event.preventDefault();

          var svg = $(this);
          svg.toggleClass('collapse').toggleClass('expand');

          $(".reviewSlide, .event-desc").slideToggle("slow");
        });          
        
        // Swap the carousel to banner
        $( "#img-vdo-slider" ).insertAfter( "#hero-title" );

        
        $(document).ready(function() {         

          if ( $("#room-price").length ){
            var rmPrice = $("#room-price").offset().top;
          }
                                    
          if ( $( "#vdo-testimo" ).length ) {
            var vdoTest = $("#vdo-testimo").offset().top;
            var vdoTestimo = (vdoTest - 700);
          }

          if ( $( "#schedule" ).length ) {
            var schT = $("#schedule").offset().top;
            var schTop = (schT + 120);
          }

          if ( $("#bsticky-pointer").length ){
            var bottomSticky = $("#bsticky-pointer").offset().top;
          }
          
          // on Scroll shrink the form
          $(window).scroll(function(){
              if ($(window).scrollTop() >= 32) {
                $(".reviewSlide, .event-desc").slideUp("slow");
              }else{
                $(".reviewSlide, .event-desc").slideDown("slow");
              }

              if ( $( "#vdo-testimo" ).length ) {
                // Hiding the sticky on scroll to footer            
                if ( ($(window).scrollTop() < vdoTestimo) || ($(window).scrollTop() > schTop) ) { 
                    $("#stickyEventSidebar4, .elearnStickySidebar").fadeIn(); //reached the desired point -- Show sticky
                }else{
                    $("#stickyEventSidebar4, .elearnStickySidebar").fadeOut();
                } 
              }else{
                $("#stickyEventSidebar4, .elearnStickySidebar").fadeIn();
              }
              
              //Highlight the "choose a date" dropdown on passing rooms
              if( ($(window).scrollTop() > rmPrice) ){
                $(".repeatable_fields_shortcode.mobile #mySelect, .register-button #singleEventMobileRegister").addClass("drpDwnHighlight");                
              }else{
                $(".repeatable_fields_shortcode.mobile #mySelect, .register-button #singleEventMobileRegister").removeClass("drpDwnHighlight");                
              }

              if ( ($("#stickyFooterSidebar").length) || ($("#stickyFooterReview").length) || ($("#stickyFooterBtn").length) ){
                if( ($(window).scrollTop() > bottomSticky) ){
                  $("#stickyFooterSidebar, #stickyFooterReview, #stickyFooterBtn").addClass("sticky-footer-highlight");
                }else{
                  $("#stickyFooterSidebar, #stickyFooterReview, #stickyFooterBtn").removeClass("sticky-footer-highlight");
                }
              }

              //Hiding the sticky on scroll - conference
              if ($(window).scrollTop() <= 900) {
                $("#stickyEventSidebar5").slideUp("slow");
              }else{
                $("#stickyEventSidebar5").slideDown();
              }

          });
          //reviews
          $('#rating .elementor-star-rating__title, #rating .elementor-star-rating').on('click', function(event) {

            // Make sure this.hash has a value before overriding default behavior
            if (this.hash !== "") {
              // Prevent default anchor click behavior
              event.preventDefault();
        
              // Store hash
              var hash = this.hash;        
              
              $('html, body').animate({
                scrollTop: $('#reviews').offset().top
              }, 800, function(){                
           
                // Add hash (#) to URL when done scrolling (default click behavior)
                //window.location.hash = hash;
              });

              //New Template with menu sticky
              $('html, body').animate({
                scrollTop: $('#hreviews').offset().top + (-168)
              }, 800, function(){                
           
                // Add hash (#) to URL when done scrolling (default click behavior)
                //window.location.hash = hash;
              });

            } // End if
          });
                    
        });
      
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    'post_template_default': {
      init: function() {

        //Blog share
        $(document).ready(function(){          
          $(".shareit").click(function(t) {            
              t.preventDefault();
              $(".list-share").slideToggle();              
          });          
        }); 

        $(document).click(function(event) {
          var container = $(".blog-share");
          if (!container.is(event.target) && !container.has(event.target).length) {
            $(".list-share").hide();
          }
        });
        //Blog share end

      }        
    },
    
    /*------------------------------------------------------------
      1.7   Pillar page template
    -------------------------------------------------------------*/
    'post_template_pillar_single_post': {
      init: function() {
        var readingTime = $(".reading-time #percent").text();

        // Scroll position on top of the pillar page
        $(window).scroll(function () { 
        var 	scvp = $(window).scrollTop(), // pixels on top hidden from view
          //dh = $(document).height(), // height of document window

   
          dh = $("#primary").height(), // height of document window
 		      dp = $(window).height(); // height of broswer window
          scrollPercent = (scvp / (dh-dp)) * 100; 
          var position = scrollPercent;

          // Change the background gradient on the progress bar
          $("#reading-progress-bar").css("background","linear-gradient(90deg, #F9AB1D " + scrollPercent + "%, rgba(255,255,255,0) " + scrollPercent + "%");

          // Replace the reading minute number
          newReadingTime = readingTime - (readingTime/100)*scrollPercent;

          if(newReadingTime < newReadingTime) {
            newReadingTime = Math.ceil(newReadingTime) + 1;
          } else if(newReadingTime < 0) {
            newReadingTime = 0;
          } else {
            newReadingTime = Math.ceil(newReadingTime);
          }
          //console.log(newReadingTime);

          $(".reading-time #percent").html(newReadingTime);

          // Make the reading time fixed

          // Make the left bar
          if ($(window).width () > 768) {
            
            $target = $('#left-floater');
            $targetWidth = $target.width();
            $targetHeight = $target.height();
            $contentOffset = $('#primary .pillar-content-area').offset().top;
            $contentHeight = $('#primary .pillar-content-area').outerHeight();

            // Run the change on scroll
            $(window).bind('scroll', function() {
              $topOffset = $(window).scrollTop();
              $heroOffset = $('.heading-post-meta').offset().top;
              $heroHeight = $('.heading-post-meta').outerHeight() - 70;
              $startFixedPosition = $heroOffset + $heroHeight;
              $startAbsolutePosition = $contentOffset + $contentHeight - $targetHeight - 100;

              // Make the widget fixed if the widget reaches the desired scroll position
              if($topOffset >= $startFixedPosition && $topOffset < $startAbsolutePosition) {

                $target.addClass('fixed');
                $target.removeClass('absolute');

                // Set the widget as absolute if widget reaches the position of the end of the content
              } else if($topOffset >= $startAbsolutePosition) {
                $target.removeClass('fixed');
                $target.addClass('absolute');
              } else {
                $target.removeClass('fixed');
                $target.removeClass('absolute');
              }
            });

          } // End if


        });

        // List inner headings on the left nav
      
  
          var sideNav = [];
          $("#primary .elementor-section-wrap h2:first-child").each(function () {
            var temp = $(this).text().toLowerCase().replace(/ +/g,'_').replace(/[0-9]/g,'').replace(/[^a-z0-9-_]/g,'').trim();
            $(this).attr("id", temp);
            sideNav.push($(this).text());
          });

          var sideNavContainer = $('#side-nav ul');

          $.each(sideNav, function(i)
          {
            var li = $('<li/>')
              .addClass('ui-menu-item')
              .attr('role', 'menuitem')
              .appendTo(sideNavContainer);
            var aaa = $('<a/>')
              .addClass('ui-all')
              .attr('href',  "#"+ sideNav[i].toLowerCase().replace(/ +/g,'_').replace(/[0-9]/g,'').replace(/[^a-z0-9-_]/g,'').trim())
              .text(sideNav[i])
              .appendTo(li);
          });

          $('.ui-all').click(function() {
            if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
              var target = $(this.hash);
              target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
              if (target.length) {
                $('html,body').animate({
                  scrollTop: target.offset().top - 100
                }, 1000);
                        $(".ui-all").removeClass("active");
                $(this).addClass("active");
                return false;
              }
            }
          });

          $(window).scroll(function(){
            $("#primary .elementor-section-wrap h2").each(function(){
              if(ScrollView($(this))){
                var id = $(this).attr("id");
                $(".ui-all").removeClass("active");
                $(".ui-all[href='#" + id + "']").addClass("active");
              }
            });
          });

          function ScrollView(element){
            var win = $(window);
            var winTop = win.scrollTop();
            var winBottom = winTop + win.height();
            var elementTop = element.offset().top;
            var elementBottom = elementTop + element.height();
        
            if((elementBottom <= winBottom) && (elementTop >= winTop)){
              return true;
            }
            return false;
          }
   

      }

    }
  };

  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Astra;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery);
